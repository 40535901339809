import React from 'react'
import PageWrapper from "../components/PageWrapper";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet"
import * as Bs from "react-bootstrap"

function Cancel({location}, props) {


  return (
    <PageWrapper footerDark>
    <Header />
    <section style={{minHeight: 800}}>

    <Helmet>
    <html lang="en" />
    <title>Thank You - Venture Capital StartupMag</title>
    <meta name="title" content="Thank You - Venture Capital StartupMag"></meta>
    <meta name="description" content="Get the ful list is venture investors in the UK. Name, website, partners, linkedin, twitter, contact form and more.."/>
   
  </Helmet>


  <Bs.Container>


  <Bs.Row style={{display: 'block', textAlign: 'center', maxWidth: 1280, marginTop: 150, marginBottom: 150, minHeight: 600}}>
    
  <h1 style={{color: 'rgb(4 23 44)', fontSize: 60, fontWeight: 700, marginTop: 0, fontFamily: '"CircularStd", sans-serif', paddingLeft: 10, paddingRight: 10}} id='line1'>Welcome to Startupmag <span role="img" aria-label="fire">🎉</span></h1>
<h1 style={{color: 'rgb(4 23 44)', fontSize: 60, fontWeight: 700, marginTop: 0, fontFamily: '"CircularStd", sans-serif', paddingLeft: 10, paddingRight: 10}}>What happens now?</h1>
<h2 style={{color: '#34383b', fontWeight: 300, marginTop: 25, marginBottom: 25, paddingLeft: 10, paddingRight: 10}}>1 - Head over to your emails <span role="img" aria-label="fire">✉️</span></h2>
<p style={{maxWidth: 750, textAlign: 'center', margin: 'auto'}}>You will receive an email from hello@startupmag.co.uk with your database download link. The email should arrive in less than 10 minutes (you may need to check your spambox).</p>
<h2 style={{color: '#34383b', fontWeight: 300, marginTop: 25, marginBottom: 25, paddingLeft: 10, paddingRight: 10}}>2 - Click on your download button </h2>
<p style={{maxWidth: 750, textAlign: 'center', margin: 'auto'}}>You can't really miss it. It's blue and says 'download'.</p>
<h2 style={{color: '#34383b', fontWeight: 300, marginTop: 25, marginBottom: 25, paddingLeft: 10, paddingRight: 10}}>3 - Start using your data!</h2>
<p>For any questions, please don't hesitate to contact us on <a href="mailto:support@vctracker.co.uk">support@startupmag.co.uk</a></p>
   
</Bs.Row>

<Bs.Row>
<Bs.Col sm={12}>


</Bs.Col>
</Bs.Row>
</Bs.Container>

</section>
<Footer/>
      </PageWrapper>
  )
}

export default Cancel
